<template>
<NavBar ref="NavbarCart"/>
<poster Title="מגוון עוגות טעימות"/>

<dialog id="favDialog">
  <form>
    <h1>{{ this.cakeAddExtras.titleCake }}</h1>
    <p>
      <label>מעוניין להוסיף תוספות? :
        <select v-model="extrasDial">
          <option value="Mnm">Mnm</option>
          <option value="שברי אוראו">שברי אוראו</option>
          <option value="עוד קומה">עוד קומה</option>
          <option value="עוד קרמל">עוד קרמל</option>
        </select>
      </label>
    </p>
    <button class="btn closeBtn" value="cancel" formmethod="dialog"><i class="bi bi-x-lg"></i></button>
    <div class="buttonDialog">
      <button class="btn btn-primary" id="confirmBtn" value="default">הוספה לסל <i class="bi bi-cart4"></i></button>
    </div>
  </form>
</dialog>

<section class="container MenuContainer">
  <div class="NavDiv">
    <NavMenu :Options="this.Options" :EditMode="this.UserEditMode" @Update-DB="this.UpdateDb" @Tag-Filter="this.FilterTag"/>
  </div>
  <div class="MenuDiv">
    

    <CakeContainer v-if="this.loadingCheck == false" v-for="(cake, index) in cakes" :key="cake" 
        :CakeImgMenu="cake.CakeImgMenu" :title="cake.titleCake"
        :price="cake.priceCake" :tags="cake.tagsCake" :idCake="cake.idCake"
        :paragrafh="cake.paragrafh" :id="cake._id" @localAddProduct="addToCart($event)"/> 

        <CakeContainerSkeleton v-else />
      <!-- <CakeContainerSkeleton/> -->
  </div>
       
  
   


</section>

  <Footer/>
</template>



<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import CakeContainer from '@/components/CakeContainer.vue'
import CakeContainerSkeleton from '@/components/CakeContainerSkeleton.vue'
import poster from '@/components/posterPic.vue'
import NavMenu from '@/components/navMenu.vue'
import axios from 'axios'


export default {
  name: 'Cart',
  components: {
    NavBar,
    Footer,
    poster,
    CakeContainer,
    CakeContainerSkeleton,
    NavMenu
  },
  data(){
    return{
    MenuText:'תפריט',
    UserEditMode:false,
    cakes:[],
    products:[],
    cakeIdSet:[],
    cakeAddExtras:[],
    CartData:[],
    Options:[
    {
      "EnglishName": "EventCookies",
      "HebrewName": "חלה"
    },
    {
      "HebrewName": "לחמים"
    },
    {
      "HebrewName": "מתוקים"
    },
    {
      "HebrewName": "שבת"
    },
    {
      "HebrewName": "עוגות מרוקאיות"
    }
    ],
    loadingCheck:null,
    idForExtra:null,
    extrasDial:'',
    
    }

  },
  methods:{
    async getdata(){

      await axios.get('/.netlify/functions/GetDataCollectionCakes').then(response => {
          console.log(response.data || []);
          var arr = []
          Object.keys(response.data).forEach(key => arr.push(response.data[key]))
          console.log(Array.isArray(arr))
          this.cakes = arr
          this.loadingCheck = false
      }).catch(error => {
          console.log(error);
      }); 
    },
    OpenExtras(item,ID){
      var NewItem = this.cakes.find((item) => item.id == ID)
      this.indexExtras.push(item)
      this.indexExtras[0].item = NewItem
      const favDialog = document.getElementById('favDialog');
      favDialog.showModal();
    },
    CloseExtras(){
      this.indexExtras = []
      const favDialog = document.getElementById('favDialog');
      favDialog.close();
    },
    addToCart(item){
      // delete item.item;
      const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
      const newItem = item; // Assume 'item' is defined somewhere
      // console.log(newItem)

      let test = SessionData || [];

      const sameIndexItems = test.filter(existingItem => existingItem.id === newItem.id);

     

      let itemExists = false;
      for (const existingItem of sameIndexItems) {
        if (newItem.extras !== existingItem.extras) {
          // If the extras are not identical, this newItem should be added
          itemExists = false;
        } else {
          // If extras are identical, update the existing item
          existingItem.inCart++;
          itemExists = true;
          break; // Exit the loop as we found a match
        }
      }

      if (!itemExists) {
        // If no matching extras were found, add the new item
        test.push(newItem);
      }
      sessionStorage.setItem('CartItems', JSON.stringify(test));
      this.SessionData()
      this.$refs.NavbarCart.SetData()
      this.CloseExtras()
      // this.scrollToElement('cart')
      
    },
  },
  created(){
    this.loadingCheck = true
    this.getdata()
  },
  mounted(){

  },
  computed: {
    InputSFilter() {
      // const query = this.InputSearch.toLowerCase();

      return this.cakes.filter(item => {
        const matchesTag = this.selectedTags === 'הכל' || item.tagsCake.includes(this.selectedTags);
        // const matchesQuery = 
        //   item.titleCake.toLowerCase().includes(query) || 
        //   item.priceCake.toLowerCase().includes(query) ||
        //   item.tagsCake.includes(query);
        // return matchesTag && matchesQuery;
        return matchesTag;
      });
      
    }

  }

  
}
</script>

<style scoped>

.empty{
  height: 1000px;
}

dialog{
  border: 1px solid transparent;
  direction: rtl;
  width: 35%;
  height: 35%;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

dialog label{
  position: relative;
  top: 20px;
  font-size: 20px;
}

dialog .buttonDialog {
  border: 1px solid none;
  width: 30%;
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  bottom: 5%;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-evenly;
}

dialog button.closeBtn{
  position: absolute;
  top: 2%;
  left: 1%;
  outline: none !important;
}
dialog button.closeBtn i{
  font-size: 28px;
}

.MenuContainer {
  position: relative;
  direction: rtl;
  margin-top: 80px;
  padding: 0px 0px 50px 0;
  border: 1px solid none;
  display: flex;
  justify-content: space-between;
  width: 100%;  
}

.MenuDiv{
  border: 1px solid none;
  position: relative;
  width: 75%;
  display: grid;
  direction: rtl;
  grid-template-columns: repeat(3,minmax(0,1fr));
  gap: 20px;
}

.NavDiv{
  border: 1px solid none;
  width: 20%;
}

@media (max-width: 1200px){
  .MenuDiv{
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
} 


@media (max-width: 984px) {

  dialog{
    width: 80% !important;
    z-index: 8787827827;
  }
  dialog label{
    font-size: 15px;
  }

  dialog select{
    position: absolute;
    top: 40px;
    right: 0;
  }
}

@media (max-width: 864px) {

  .MenuContainer{
    flex-direction: column;
  }

  .MenuDiv{
    grid-template-columns: repeat(2,minmax(0,1fr));
    width: 100%;
    gap:10px;
  }

  .NavDiv{
    border: 1px solid none;
    width: 100%;
  }
}


</style>




